/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import trees from "assets/images/curved-images/trees.jpeg";
import { timelineItem } from "examples/Timeline/TimelineItem/styles";
import { BACKEND_URL } from "configs";

let changedPassword = false;

function PasswordReset() {
  const [message, setMessage] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [passwordError, setPasswordError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    var badPass
    if (password !== password2) {
        setPasswordError("Passwords do not match");
        badPass = true
    } else if (password.length < 8) {
        setPasswordError("Password must be at least 8 characters");
        badPass = true
    } else {
        setPasswordError("");
        badPass = false
    }
    if (!badPass) {
      handleSubmission();
    }
  };

  let handleSubmission = async () => {
    // get uid and token from url
    console.log(window.location.href);
    const urlParams = window.location.href.split("/");
    let uid = urlParams[urlParams.length - 2];
    let token = urlParams[urlParams.length - 1];
    try {
      setLoading(true)
        let res = await fetch(BACKEND_URL + "/auth/users/reset_password_confirm/", { // use other let statement when backend is working properly
        // let res = await fetch("http://django-backend-env-1-backup.us-west-2.elasticbeanstalk.com/auth/users/reset_password_confirm/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uid: uid,
                token: token,
                new_password: password,
                re_new_password: password2
            }),
        });
        setLoading(false)
        if (res.status === 204) {
          setPasswordError("");
          setMessage("Your password has been changed. Redirecting to sign in...");
          await new Promise(r => setTimeout(r, 3000));
          window.location.href="/authentication/sign-in"
          changedPassword = true;
          return;
      } 
        if (res.headers.get("content-length") !== "0") { // if content-length > 0
            var resJson = await res.json();
        } 
       
      if (resJson.new_password) {
            setPasswordError(resJson.new_password[0]);
            setMessage("");
        } 
        else if (res.status === 400 && changedPassword === false) {
            setPasswordError("An error occured. This link may have expired.");
            setMessage("");
        } else if (res.status === 400 && changedPassword === true) {
            setPasswordError("");
            setMessage("Your password has already been changed. Sign in.");
        }
    } catch (err) {
        console.log(err);
    }
  };

  return (
    <BasicLayout
      title="Reset Password"
      description=""
      image={trees}
      color={"success"}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="New password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e)=>{ if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit()
    }}} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Confirm new password" value={password2} onChange={(e) => setPassword2(e.target.value)} onKeyDown={(e)=>{ if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit()
    }}}/>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="success" fullWidth onClick={handleSubmit}>
          {loading?  <div className="loader">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
              </div> :"reset password"}
          </SoftButton>
        </SoftBox>

        {passwordError &&
              <SoftTypography
                color="light"
                variant="h6"
                style={{textAlign: 'center'}}
              >{passwordError}</SoftTypography>
            }
        <SoftTypography
                 
                  variant="h6"
                  color="light"
                  textGradient
                  fontWeight="medium"
                >
                  {message}
                </SoftTypography>
      </SoftBox>
    </BasicLayout>
  );
}

export default PasswordReset;
