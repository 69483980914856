/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import { Card, Icon, Tooltip, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Landing Page layout components
import CoverLayout from "layouts/landing-page/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import trees from "assets/images/curved-images/trees.jpeg";
import Dashboard from "layouts/dashboard";
import { maxHeight } from "@mui/system";

function LandingPage() {
  let handleFleetefyButton = async () => {
    window.location.href = "/fleetefy";
  };
  let handleOptefyButton = async () => {
    window.location.href = "/optefy";
  };
  let handleGreetefyButton = async () => {
    window.location.href = "https://solv4x.ai";
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* <CoverLayout
      //title="TEST LANDING PAGE"
      //description="Enter your email and password to sign in"
      image={trees}
      color={"success"}
    >
</CoverLayout> */}

      <Grid container>
        <Card>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              maxHeight: "800px",
            }}
          >
            <div style={{ width: "500px", alignItems: "center" }}>
              <SoftTypography style={{ textAlign: "center" }} variant="h4">
                Welcome{" "}
                {localStorage.getItem("first_name") + " " + localStorage.getItem("last_name")},
              </SoftTypography>
              <SoftTypography style={{ padding: "30px", textAlign: "center" }} variant="subtitle1">
                Please Select An Option Below
              </SoftTypography>
              <SoftBox style={{ padding: "30px" }}>
                <SoftButton onClick={handleFleetefyButton}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 275,

                      height: 150,
                    }}
                  >
                    <div>
                      <SoftTypography variant="h5">Fleetefy</SoftTypography>
                      <SoftTypography variant="body2">Phase 1: Adoption</SoftTypography>
                      <SoftTypography variant="body2">
                        Plan your fleet&apos;s transition to EV
                      </SoftTypography>
                    </div>
                  </div>
                </SoftButton>
              </SoftBox>

              <SoftBox style={{ padding: "30px" }}>
                <SoftButton onClick={handleOptefyButton}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 275,

                      height: 150,
                    }}
                  >
                    <div>
                      <SoftTypography variant="h5">Optefy</SoftTypography>
                      <SoftTypography variant="body2">Phase 2: Operation</SoftTypography>
                      <SoftTypography variant="body2">
                        Manage and optimize your fleet&apos;s charging
                      </SoftTypography>
                    </div>
                  </div>
                </SoftButton>
              </SoftBox>
            </div>

            <div style={{ flex: 1 }}>
              {/* image here */}
              <img src={trees}></img>
            </div>
          </div>
        </Card>
      </Grid>
    </DashboardLayout>
  );
}

export default LandingPage;
