/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import Icon from "@mui/material/Icon";


// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Tesla from "assets/images/logos/tesla.png"
import GM from "assets/images/logos/gm.png"
import Rivian from "assets/images/logos/rivian.png"
import Ford from "assets/images/logos/ford.png"

const action = (
  <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
    more_vert
  </Icon>
);

function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const authorsTableData = {
  columns: [
    { name: "vehicle make", align: "left" },
    { name: "count", align: "left" },
    { name: "location", align: "center" },
    { name: "status", align: "center" },
    { name: "lease renewal", align: "center" },
    { name: "action", align: "center" }
  ],

  rows: [
    {
      "vehicle make": <Author image={Ford} name="Ford" email="F150" />,
      count: 2,
      charger: <Function job="Tesla" org="SuperCharger" />,
      status: (
        <SoftBadge variant="gradient" badgeContent="on road" color="success" size="xs" container />
      ),
      "lease renewal": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          23/04/22
        </SoftTypography>
      ),
      location: <SoftTypography variant="caption" color="secondary" fontWeight="medium" align="left">
      Toronto, ON
    </SoftTypography>,
      action
      ,
    },
    {
      "vehicle make": <Author image={Ford} name="Ford" email="F250" />,
      charger: <Function job="Tesla" org="PowerWall" />,
      location: <SoftTypography variant="caption" color="secondary" fontWeight="medium" align="left">
      Brampton, ON
    </SoftTypography>,
      count: 12,
      status: (
        <SoftBadge variant="gradient" badgeContent="decomissioned" color="secondary" size="xs" container />
      ),
      "lease renewal": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          11/01/22
        </SoftTypography>
      ),
      action,
    },
    {
      "vehicle make": <Author image={GM} name="GM" email="Yukon XL" />,
      charger: <Function job="ChargePoint" org="Model X" />,
      location: <SoftTypography variant="caption" color="secondary" fontWeight="medium" align="left">
      Milton, ON
    </SoftTypography>,
      count: 5,
      status: (
        <SoftBadge variant="gradient" badgeContent="on road" color="success" size="xs" container />
      ),
      "lease renewal": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          19/09/22
        </SoftTypography>
      ),
      action,
    },
    {
      "vehicle make": <Author image={GM} name="GM" email="Terrain" />,
      charger: <Function job="ChargePoint" org="Model Y" />,
      location: <SoftTypography variant="caption" color="secondary" fontWeight="medium" align="left">
      Toronto, ON
    </SoftTypography>,
      count: 6,
      status: (
        <SoftBadge variant="gradient" badgeContent="on road" color="success" size="xs" container />
      ),
      "lease renewal": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          24/12/22
        </SoftTypography>
      ),
      action,
    },
    {
      "vehicle make": <Author image={Ford} name="Ford" email="Explorer" />,
      charger: <Function job="ChargePoint" org="Model X" />,
      location: <SoftTypography variant="caption" color="secondary" fontWeight="medium" align="left">
        Mississauga, ON
      </SoftTypography>,
      count: 3,
      status: (
        <SoftBadge variant="gradient" badgeContent="decomissioned" color="secondary" size="xs" container />
      ),
      "lease renewal": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          04/10/22
        </SoftTypography>
      ),
      action,
    },
    {
      "vehicle make": <Author image={GM} name="GM" email="Hummer" />,
      charger: <Function job="ABB" org="Pride EV" />,
      location: <SoftTypography variant="caption" color="secondary" fontWeight="medium" align="left">
      Toronto, ON
    </SoftTypography>,
      count: 1,
      status: (
        <SoftBadge variant="gradient" badgeContent="decomissioned" color="secondary" size="xs" container />
      ),
      "lease renewal": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          14/09/22
        </SoftTypography>
      ),
      action,
    },
  ],
};

export default authorsTableData;
