/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import trees from "assets/images/curved-images/trees.jpeg";
import { BACKEND_URL } from "configs";

function ForgotPassword() {
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setIsLoading] = useState(false)

  let handleSubmission = async () => {
    if (isValidEmail(email)) {
      setIsLoading(true)
        try {
            let res = await fetch(BACKEND_URL + "/auth/users/reset_password/", { // use other let statement when backend is working properly
            // let res = await fetch("http://django-backend-env-1-backup.us-west-2.elasticbeanstalk.com/auth/users/reset_password/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email
                }),
            });
            setIsLoading(false)
            if (res.status === 204 || res.status === 400) {
                
                setMessage("If an account exists with this email, a password reset link has been sent to it.");
            } else {
                setMessage("An error occurred.");
            }
        } catch (err) {
            console.log(err);
        }
    } else {
        setMessage("Email is invalid");
    }
    
  };

  function isValidEmail(email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  return (
    <BasicLayout
      title="Forgot Password"
      description=""
      image={trees}
      color={"success"}
    
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={(e)=>{ if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmission()
    }}}/>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="success" fullWidth onClick={handleSubmission}>
            {loading?  <div className="loader">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
              </div> :"reset password"}
          </SoftButton>
        </SoftBox>

        {message &&
              <SoftTypography
                color="light"
                variant="h6"
                style={{textAlign: 'center'}}
              >{message}</SoftTypography>
            }
      </SoftBox>
    </BasicLayout>
  );
}

export default ForgotPassword;
