import colors from "assets/theme/base/colors";
const LineGraphData = {
    labels: ['October', 'November', 'December', 'January', 'February', 'March'],
  datasets: [
    {
      label: 'Energy cost',
      data: [120, 100, 80, 90, 85, 70],
      color: '#9ca2b7'
    }
  ]
  };

export default LineGraphData;