/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import trees from "assets/images/curved-images/trees.jpeg";
import { BACKEND_URL } from "configs";

function Activate() {
  const [message, setMessage] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Click here to activate account");

  let handleSubmission = async () => {

    if (buttonText === "Continue to sign in."){
      window.location.href = "/authentication/sign-in"
    }
    setMessage("");
    setIsLoading(true);
    // get uid and token from url
    console.log(window.location.href);
    const urlParams = window.location.href.split("/");
    let uid = urlParams[urlParams.length - 2];
    let token = urlParams[urlParams.length - 1];
    console.log(uid);
    console.log(token);
    try {
      let res = await fetch(
        BACKEND_URL + "/auth/users/activation/",
        {
          // use other let statement when backend is working properly
          // let res = await fetch("http://django-backend-env-1-backup.us-west-2.elasticbeanstalk.com/auth/users/activation/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: uid,
            token: token,
          }),
        }
      );
      setIsLoading(false);
      if (res.status === 204) {
        setButtonText("Continue to sign in.");
        setMessage("Account has been activated.");
      } else if (res.status === 403) {
        setButtonText("Continue to sign in.");
        setMessage("Account is already activated.");
      } else {
        setButtonText("Continue to sign in.");
        setMessage("Could not complete activation");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BasicLayout title="Welcome to Solv4x Platform" description="" image={trees} color={"success"}>
      <SoftBox component="form" role="form">
        <SoftBox display="flex" alignItems="center"></SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="success" fullWidth onClick={handleSubmission}>
            {loading ? (
              <div className="loader">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
              </div>
            ) : (
              buttonText
            )}
          </SoftButton>
        </SoftBox>
        {/*}
        {message &&
          <SoftTypography
            color="success"
            variant="body2"
          >{message}</SoftTypography>
        }
        */}
        <SoftTypography variant="h6" color="light" fontWeight="bold" style={{textAlign: 'center', marginTop: '5px'}}>
          {message}
        </SoftTypography>
        <SoftBox mt={3} textAlign="center"></SoftBox>
      </SoftBox>
    </BasicLayout>
  );
}

export default Activate;
