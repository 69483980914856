/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from "react";
import './assets/theme/loading.css';
// react-router components
import { Routes, Route, Navigate, useLocation, Redirect } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard React routes
import routes from "routes";

// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo-ct.png";
import { SettingsRemote } from "@mui/icons-material";

import { BACKEND_URL } from "configs";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);

  const [loggedIn, setLoggedin] = React.useState(false);

  React.useEffect(()=>{
    async function updateLogged() {
    try {
      const access_token = localStorage.getItem('access_token');
      let res = await fetch(BACKEND_URL + "/auth/users/me/", {
        method: "GET",
        headers: {
          'Authorization' : 'JWT ' + access_token
          }
      });
      if (res.status === 200) {
        console.log("Logged in");
        res.json().then(data => {localStorage.setItem("first_name", data["first_name"]); localStorage.setItem("last_name", data["last_name"])})
        
        setLoggedin(true)
        setLoading(false);
      } else {
        console.log("Not logged in", res);
        setLoggedin(false)
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } }
    updateLogged();
  }, [])

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getAppNumber = () =>{
    if (pathname.startsWith("/fleetefy")) {
      return 1
    } else if (pathname.startsWith("/optefy")) {
      return 2
    } else if (pathname.startsWith("/launchpad")) {
      return 3
    }
  }
  const filterRoutes = () => {
    if (pathname.startsWith("/fleetefy")) {
      return routes.filter((e) => e.noNav !== true && e.App.includes("fleetefy"));
    } else if (pathname.startsWith("/optefy")) {
      return routes.filter((e) => e.noNav !== true && e.App.includes("optefy"));
    } else if (pathname.startsWith("/launchpad")) {
      return routes.filter((e) => e.noNav !== true && e.App.includes("launchpad"));
    }

    return [];
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
     if(route.route){
      return <Route exact path={route.route}
      element={route.protected ? loading? loader() :  loggedIn ? route.component : <Navigate to="authentication/sign-in"/> : route.component}
      key={route.key} />;
     }

      return null;
    });



  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Fleetefy"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName={getAppNumber() === 1 ? "Fleetefy by Solv4x": getAppNumber() === 2 ? "Optefy By Solv4x": "Solv4x EV Platform"}
            routes={filterRoutes()}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
      </Routes>
    </ThemeProvider>
  );
}


function loader () {
  return (
    <div className="loader-container">
    <div className="spinner"></div>
  </div>
  );
}