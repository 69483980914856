/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { gasStationNames } from "configs";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Billing page components
import Transaction from "layouts/adoptioncalc/components/Transaction";
import React from "react";
import { utilityNames } from "configs";

function Transactions(props) {

  const [showFleet, setShowFleet] = React.useState(props.fleet)

  React.useEffect(()=>{
    setShowFleet(props.fleet)

  }, [props.fleet])
  return (
    <div>
    {Object.keys(showFleet).length !== 1 ?
    <Card sx={{ height: "100%" }}>
      <SoftBox  justifyContent="space-between" alignItems="center" pt={3} px={2}>

        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Overview: {showFleet.name} Profile
        </SoftTypography>
       
      </SoftBox>
      
      <SoftBox pt={3} pb={2} px={2}>
        <SoftBox mb={2} style={{display: 'flex', justifyContent: 'space-between'}}>
  
          <SoftTypography
            variant="caption"
            color="text"
            fontWeight="bold"
            textTransform="uppercase"
          >
            Equipment Specifics
          </SoftTypography>
          <SoftTypography
            variant="caption"
            color="text"
            fontWeight="bold"
          pr={2}
          >
            Count
          </SoftTypography>
        </SoftBox>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {showFleet.vehicles.map((v)=>{
            return <Transaction
            key={v}
            color="dark"
            icon="directions_car"
            name={v.brand}
            description={v.model + " " + v.trim}
            value={v.count}
          />
          })}
         
        </SoftBox>
        <SoftBox mt={1} mb={2}>
          <SoftTypography
            variant="caption"
            color="text"
            fontWeight="bold"
            textTransform="uppercase"
          >
            Operator Specifics
          </SoftTypography>
        </SoftBox>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <Transaction
            color="dark"
            icon="local_gas_station"
            name="Gas Station Location"
            description={gasStationNames[showFleet.city]}
            value={"¢" + showFleet.gas + " per litre"}
          />
           <Transaction
            color="dark"
            icon="electric_meter"
            name="Utility"
            description={utilityNames[showFleet.city]}
            value={"$" + showFleet.energy + " per Kwh"}
          />
         <Transaction
            color="dark"
            icon="edit_road"
            name="Driving Behaviour"
            description="City Driving %"
            value={showFleet.cityDriving + "%"}
        />
        </SoftBox>
      </SoftBox>
    </Card> : <Card style={{minHeight: '25em', padding: '1em'}}> <div style={{display: 'flex', alignItems: 'stretch', width: '100%'}}><SoftBox style={{border: '1.5px dashed', width: '100%', borderRadius: '8px', height: '23em', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <SoftTypography variant="h5">Add {showFleet.name} to see overview</SoftTypography>
      </SoftBox></div></Card>}
    </div>
  );
}

export default Transactions;
