
export const carBrands = [{ value: "tesla", label: "Tesla" },{ value: "chevrolet", label: "Chevrolet" }, { label: "Ford", value: "ford" }, { value: "gm", label: "General Motors" }, {value: "rivian", label: "Rivian"}]
export const carModels = { "tesla": [{ value: 'cybertruck', label: 'CyberTruck' }], "ford": [{ value: 'f150', label: '2021 F150' },  { value: 'f150lightning', label: '2022 F150 Lightning' }], 'gm': [{ value: 'sierra1500', label: '2021 Sierra 1500' }], "chevrolet": [{value: "silverado1500", label: "2021 Silverado 1500"}], "rivian": [{value: "r1t", label: "R1T"}] }
export const carTrims = { "cybertruck": [{ value: 'cybertrucksingle', label: 'Single Motor' }, { value: 'cybertruckdouble', label: 'Double Motor' }, { value: 'cybertrucktri', label: 'Tri Motor' }],"silverado1500": [{value: "silverado15003.0lturbodiesel", label : "3.0L Turbo Diesel/10 Speed Auto"}],'f150': [{value: 'f1502.7ecoboost', label: "2.7 Litre EcoBoost"}, {value: 'f1503.0powerboost', label: "3.0 Litre PowerBoost"}, {value: 'f1503.5powerboost', label: "3.5 Litre PowerBoost"}], 'f150lightning': [{ value: 'f150lightningstandard', label: 'Standard Range Battery' }, { value: 'f150lightningextended', label: 'Extended Range Battery' }], 'sierra1500': [{ value: 'sierra15003.0lturbodiesel', label: '3.0L I-6 turbo-diesel / 10-speed auto.' }], "r1t":[{value: "r1tquad", label: "Quad Motor"}] }
export const cities = [
    { value: "toronto", label: "Toronto, ON, Canada" },
    { value: "vancouver", label: "Vancouver, BC, Canada" },
    { value: "sacramento", label: "Sacramento, California, USA" },
    { value: "houston", label: "Houston, Texas, USA" }
]

export const cityPrices = {"toronto": {gas: 166.4, energy: 0.115},"sacramento": {gas: 181.4, energy: 0.18},"vancouver": {gas: 191.7, energy: 0.1408},"houston": {gas: 117.0, energy: 0.16} }
export const gasStationNames = {"toronto": "Toronto, ON", "sacramento": "Sacramento, CA", "vancouver": "Vancouver, BC", "houston": "Houston, TX"}
export const utilityNames = {"toronto": "Toronto Hydro", "sacramento": "SMUD", "vancouver": "BC Hydro", "houston": "Houston Centerpoint Energy"}
export const KMPerFuel = {cybertrucksingle: 2.90, cybertruckdouble: 2.95, cybertrucktri: 3.01,"silverado15003.0lturbodiesel": {city: 22.33, highway: 18.90}, "f1502.7ecoboost": {city: 25.86, highway: 20.47}, 'f1503.0powerboost': {city: 24.57, highway: 18.20}, 'f1503.5powerboost': {city: 20.47, highway: 20.47}, 'f150lightningstandard':2.99, 'f150lightningextended': 3.07,'sierra15003.0lturbodiesel': {city: 22.33, highway: 18.90} , "r1tquad":3.02    }
export const baseMSRPs = {cybertrucksingle: 51424, cybertruckdouble: 64312, cybertrucktri: 90089,"silverado15003.0lturbodiesel": 58761.47, "f1502.7ecoboost": 58639.03, 'f1503.0powerboost': 62660, 'f1503.5powerboost': 75380.16, 'f150lightningstandard':68274, 'f150lightningextended': 95984,'sierra15003.0lturbodiesel': 55998 , "r1tquad":79500    }
export const vehcileType = {cybertrucksingle: "EV", cybertruckdouble: "EV", cybertrucktri: "EV","silverado15003.0lturbodiesel": "ICE", "f1502.7ecoboost": "ICE", 'f1503.0powerboost': "ICE", 'f1503.5powerboost': "ICE", 'f150lightningstandard':"EV", 'f150lightningextended': "EV",'sierra15003.0lturbodiesel': "ICE" , "r1tquad":"EV"    }

//export const BACKEND_URL = "http://localhost:8000"
// export const BACKEND_URL = "https://mgmnmrvfmy.us-west-2.awsapprunner.com" // development backend url
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL // development backend url