/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Slide from "@mui/material/Slide";
import { Card, Grid, Icon, Switch, Tooltip } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import DataTable from "examples/Tables/DataTable";
import { v4 as uuidv4 } from "uuid";
import { carBrands, carModels, carTrims, cities } from "configs";
import { cityPrices } from "configs";
import { baseMSRPs } from "configs";
import { vehcileType } from "configs";
import { KMPerFuel } from "configs";

// const carModels = {"tesla": [{value: 'modelx', label: 'Model X'}, {value: 'modely', label: 'Model Y'}], "ford": [{value: 'f150', label: 'F150'},{value: 'f250', label: 'F250'}, {value: 'f150lightning', label: 'F150 Lightning'}], 'gm': [{value: 'sierra1500', label: 'Sierra 1500'}] }
// const carTrims = {"modelx": [{value: 'longrangemodelx', label: 'Long Range'}, {value: 'shortrange', label: 'Short Range'}], "modely": [{value: 'longrange', label: 'LongRange'},{value: 'shortrange', label: 'Short Range'}], 'f150lightning': [{value: 'f150lightning', label: 'F150 Lightning'}], 'sierra1500': [{value: '3.0lstrone', label: '3.0 L Strone'}] }

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddFleet(props) {
  const financingOptions = [{value: 1, label: "1 year"}, {value: 2, label: "2 years"},{value: 3, label: "3 years"},{value: 4, label: "4 years"},{value: 5, label: "5 years"}]
  const [vehicles, setVehicles] = React.useState([]);
  const [selectedCompany, setCompany] = React.useState({ value: "", label: "" });
  const [selectedTrim, setTrim] = React.useState({ value: "", label: "" });
  const [selectedCity, setSelectedCity] = React.useState();
  const [gasPrices, setGasPrice] = React.useState();
  const [energyPrice, setEnergyPrice] = React.useState();

  const [selectedModel, setmodel] = React.useState({ value: "", label: "" });
  const [financingTerm, setFinancingTerm] = React.useState({ value: "", label: "" })
  const [mileage, setMileage] = React.useState("");
  const [msrp, setMsrp] = React.useState()
  const [cityDriving, setCityDriving] = React.useState("");
  const [count, setCount] = React.useState("");
  const [monthlyemi, setemi] = React.useState("");
  const [fleetVehiclesOBJ, setFleetVehiclesOBJ] = React.useState([]
  );

  const [deleteID, setDeleteID] = React.useState(undefined)

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  React.useEffect(()=>{
    if(deleteID){
    const filtered_list = vehicles.filter((v) => v.id !== deleteID)
    setVehicles(filtered_list); 
    const filtered_obj = fleetVehiclesOBJ.filter((v)=> v.id!== deleteID)
    setFleetVehiclesOBJ(filtered_obj);
    }
  }, [deleteID])


  const handleClose = () => {
    setVehicles([]);
    setFleetVehiclesOBJ([]);
    setCityDriving("");
    setSelectedCity();
    setGasPrice("");
    setEnergyPrice("");
    props.setOpen(false);
  };

  const addVehicle = () => {
    const newID = uuidv4();
    let monthlyOperating = 0
    if(vehcileType[selectedTrim.value] === "ICE"){
      let cityKM = parseFloat((mileage * (cityDriving/100)).toFixed(2));
      let highwayKM = mileage - cityKM

      let dollar_per_km_city = (gasPrices/100) / KMPerFuel[selectedTrim.value].city;
      monthlyOperating += parseFloat((dollar_per_km_city * cityKM).toFixed(2))


     
     
      let dollar_per_km_highway = (gasPrices/100) / KMPerFuel[selectedTrim.value].highway;
      monthlyOperating += parseFloat((dollar_per_km_highway * highwayKM).toFixed(2))
      }
      else{
        let dollar_per_km = energyPrice / KMPerFuel[selectedTrim.value];
      monthlyOperating = parseFloat((dollar_per_km * mileage).toFixed(2))
      }
      
   
    const new_row = [
      {
        id: newID,
        brand: selectedCompany.label,
        vehicle: selectedModel.label + " " + selectedTrim.label,
        type: vehcileType[selectedTrim.value],
        mileage: mileage + "KM",
        city: cityDriving + "%",
        operatingcosts: "$" + monthlyOperating.toFixed(2) + "/month",
        count: count,
        fixedcosts: "$" + monthlyemi + "/month",
        delete: (
          <SoftButton
            variant="text"
            color="error"
            onClick={() => setDeleteID(newID)}
          >
            Delete <Icon style={{ marginLeft: "5px" }}>delete</Icon>
          </SoftButton>
        ),
      },
    ];
    const new_obj = [
      {
        id: newID,
        brand: selectedCompany.label,
        model: selectedModel.label,
        trim: selectedTrim.label,
        type: vehcileType[selectedTrim.value],
        mileage: mileage,
        fixedcosts: monthlyemi,
        term: financingTerm.value,
        operatingcosts: monthlyOperating,
        msrp: msrp,
        cityDriving: cityDriving,
        count: count,
        km_per_fuel: KMPerFuel[selectedTrim.value],
      },
    ];
    setFleetVehiclesOBJ(fleetVehiclesOBJ.concat(new_obj));

    setVehicles(vehicles.concat(new_row));
    console.log("ADDED", new_obj, new_row, vehicles, fleetVehiclesOBJ)
    setCompany({ value: "", label: "" });
    setmodel({ value: "", label: "" });
    setTrim({ value: "", label: "" });
    setMileage("");
    setCityDriving("");
    setCount("");
    setemi("");
    setFinancingTerm({ value: "", label: "" })
  };

  // React.useEffect(()=>{
   
  // }, [props.fleet])

  React.useEffect(()=>{
    if(props.open){
      if(Object.keys(props.fleet).length !== 1){
        if(props.fleet.gas){
          setGasPrice(props.fleet.gas)
        }
        if(props.fleet.energy){
          setEnergyPrice(props.fleet.energy)
        }
        if(props.fleet.city){
          setSelectedCity(cities.filter((city)=>city.value === props.fleet.city)[0])
        }
        setVehicles([])
        setFleetVehiclesOBJ(props.fleet.vehicles)
        if(props.fleet.vehicles){
          
          const newVehicleRows = []
          props.fleet.vehicles.forEach(veh => {
            console.log("LOOP", vehicles, props.fleet);
            newVehicleRows.push({
              id: veh.id,
              brand: veh.brand,
              vehicle: veh.model + " " + veh.trim,
              type: veh.type,
              mileage: veh.mileage + "KM",
              city: veh.cityDriving + "%",
              operatingcosts: "$" + veh.operatingcosts + "/month",
              count: count,
              fixedcosts: "$" + veh.fixedcosts + "/month",
              count: veh.count,
              delete: (
                <SoftButton
                  variant="text"
                  color="error"
                  onClick={() => {
                   setDeleteID(veh.id)
                  }}
                >
                  Delete <Icon style={{ marginLeft: "5px" }}>delete</Icon>
                </SoftButton>
              ),
            })
            setVehicles(newVehicleRows)
          });
        }
            }
            else{
              setVehicles([])
              setFleetVehiclesOBJ([])
            }
    }
  }, [props.open])
  const handleSave = () => {
    const newFleet = { name: props.fleet.name };
    newFleet["vehicles"] = fleetVehiclesOBJ;
    newFleet["city"] = selectedCity.value;
    newFleet["gas"] = gasPrices;
    newFleet["energy"] = energyPrice;

    let totalOperating = 0;
    let totalFixed = 0;
    let totalKMDriven = 0;
    let cityKMDriven = 0;
    let totalMSRP = 0;

    fleetVehiclesOBJ.forEach((vehicle) => {
      totalOperating += (vehicle.operatingcosts*vehicle.count*12)
totalFixed += (vehicle.fixedcosts*vehicle.count*12)
totalKMDriven += (vehicle.mileage * vehicle.count)
cityKMDriven += (vehicle.mileage* vehicle.count * (vehicle.cityDriving /100))
totalMSRP += (vehicle.msrp*vehicle.count)
    });
    newFleet["annualOperating"] = parseFloat(totalOperating.toFixed(2))
    newFleet["annualFixed"] = parseFloat(totalFixed.toFixed(2))
    newFleet["cityDriving"] = parseFloat((cityKMDriven/totalKMDriven).toFixed(2))
    newFleet["totalMSRP"] = totalMSRP

    
    if (props.fleet.name === "Current Fleet") {
      props.setCurrentFleet(newFleet);
    } else if (props.fleet.name === "Comparison Fleet 1") {
      props.setFleet1(newFleet);
    } else if (props.fleet.name === "Comparison Fleet 2") {
      props.setFleet2(newFleet);
    }
    

    handleClose();
  };
  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative", padding: "0.5em" }}>
          <Toolbar>
            {/* <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton> */}
            <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
              Add New Fleet
            </Typography>
            {
              vehicles.length !== 0 && selectedCity !== "" && gasPrices && energyPrice ?
              

            
            <SoftButton
              autoFocus
              color="success"
              variant="gradient"
              onClick={handleSave}
              style={{ marginRight: "20px" }}
            >
              Save
            </SoftButton>:<Tooltip title="Add at least one vehicle and specify all fields before saving.">
<SoftButton  variant="outlined" color="secondary"  style={{ marginRight: "20px" }}>Save</SoftButton>
</Tooltip>
}
            <SoftButton autoFocus color="dark" variant="gradient" onClick={handleClose}>
              Close
            </SoftButton>
          </Toolbar>
        </AppBar>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={2} lineHeight={1}>
                
                <SoftTypography variant="h6" fontWeight="medium" style={{ marginTop: "20px" }}>
                  Fleet Location
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Choose your operating location
                </SoftTypography>
                <Divider />
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-around"
                  height="100%"
                >
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        City
                      </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                      placeholder="Select your city"
                      options={cities}
                      value={selectedCity}
                      onChange={(option) => {
                        setSelectedCity(option);
                        setGasPrice(cityPrices[option.value].gas);
                        setEnergyPrice(cityPrices[option.value].energy);
                      }}
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Gas Price/Litre (¢)
                      </SoftTypography>
                    </SoftBox>

                    <SoftInput
                      type="number"
                      value={gasPrices}
                      onChange={(e) => setGasPrice(e.target.value)}
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Energy Price/kWh ($)
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      type="number"
                      value={energyPrice}
                      onChange={(e) => setEnergyPrice(e.target.value)}
                    />
                  </div>
                </SoftBox>
                <Divider />
                <SoftTypography variant="h6" fontWeight="medium">
                  Fleet Vehicles
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Add vehicles to {props.fleet.name}
                </SoftTypography>
                <Divider />
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-around"
                  height="100%"
                >
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Vehicle Make
                      </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                      placeholder="Select a vehicle make"
                      options={carBrands}
                      value={selectedCompany}
                      onChange={(option) => {
                        setCompany(option);
                      }}
                    />{" "}
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Vehicle Model
                      </SoftTypography>
                    </SoftBox>

                    <SoftSelect
                      placeholder="Select a vehicle model and year"
                      value={selectedModel}
                      onChange={(option) => {
                        setmodel(option);
                      }}
                      options={carModels[selectedCompany.value]}
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Vehicle Trim
                      </SoftTypography>
                    </SoftBox>

                    <SoftSelect
                      placeholder="Select a vehicle trim"
                      options={carTrims[selectedModel.value]}
                      value={selectedTrim}
                      onChange={(option) => {
                        setTrim(option);
                        setMsrp(baseMSRPs[option.value])
                       
                      }}
                    />
                  </div>
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-around"
                  height="100%"
                  style={{ marginTop: "10px" }}
                >
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Annual Mileage (KM)
                      </SoftTypography>
                    </SoftBox>

                    <SoftInput
                      placeholder="Enter your annual mileage in kilometers"
                      type="number"
                      value={mileage}
                      onChange={(e) => setMileage(e.target.value)}
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        City Driving %
                      </SoftTypography>
                    </SoftBox>

                    <SoftInput
                      placeholder="Enter the % of your driving which is in city"
                      type="number"
                      value={cityDriving}
                      onChange={(e) => setCityDriving(e.target.value)}
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Count
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      placeholder="Enter the number of vehicles in this specification"
                      type="number"
                      value={count}
                      onChange={(e) => setCount(e.target.value)}
                    />
                  </div>
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-around"
                  height="100%"
                  style={{ marginTop: "10px" }}
                >
                  {selectedTrim.value !== "" && 
                  <>
                  {props.fleet.name !== "Current Fleet" && (
                    <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          MSRP
                        </SoftTypography>
                      </SoftBox>

                      <SoftInput
                        placeholder={selectedTrim.value === "" ? "Please select a vehicle model and trim first.": "Enter MSRP of vehicle"}
                        type="number"
                        value={msrp}
                      
                        onChange={(e) => {setMsrp(e.target.value);
                          if(e.target.value)
                          {
                          if(financingTerm.value !== 0){
                            const months = financingTerm.value * 12
                            const cost = Math.round((e.target.value/months+Number.EPSILON) * 100) / 100
                            setemi(cost)
                          
                          }
                          else if(financingTerm.value === 0){
                            setemi(0)
                          }
                          
                        } else{
                          setemi("")
                        }
                          
                        
                        }}
                      />
                    </div>
                  )}
                  {props.fleet.name !== "Current Fleet" && (
                    <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Financing/Lease Term
                        </SoftTypography>
                      </SoftBox>

                      <SoftSelect
                      placeholder="Select a financing/lease term"
                      options={financingOptions}
                      value={financingTerm}
                     
                      onChange={(option) => {
                        setFinancingTerm(option);
                        if(option.value !== 0){
                          const months = option.value * 12
                          const cost = parseFloat((msrp/months).toFixed(2))
                          setemi(cost)
                        
                        
                        }
                        else if(option.value === 0){
                          setemi(0)
                        }
                      }}
                     
                    />
                    </div>
                  )}

                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Monthly Lease/Finance Expense
                      </SoftTypography>
                    </SoftBox>

                    <SoftInput
                      placeholder={props.fleet.name !== "Current Fleet" ? "" : "Enter your monthly lease/finance expense."}
                      type="number"
                      value={monthlyemi}
                      disabled={props.fleet.name !== "Current Fleet"}
                      onChange={(e) => setemi(e.target.value)}
                    />
                  </div></>
                  }
                </SoftBox>
                <SoftBox style={{ display: "flex", justifyContent: "flex-end" }}>
                  {selectedTrim.value !== "" && mileage && cityDriving && count && monthlyemi !== "" && gasPrices && energyPrice && selectedCity ?
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    style={{ marginTop: "20px", marginRight: "10px" }}
                    onClick={addVehicle}
                  >
                    Add Vehicle
                  </SoftButton>
: 
<Tooltip title="Fill out all fields above before adding vehicle.">
<SoftButton  variant="outlined" color="secondary" style={{ marginTop: "20px", marginRight: "10px" }}>Add  Vehicle</SoftButton>
</Tooltip>
}
                </SoftBox>
                <Divider />
                <SoftTypography variant="h6" fontWeight="medium" style={{ marginTop: "20px" }}>
                  Added Vehicles
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Currently added vehicles for your fleet
                </SoftTypography>
                <Divider />
                <DataTable
                  table={{
                    columns: [
                      { Header: "brand", accessor: "brand", width: "10%" },
                      { Header: "vehicle", accessor: "vehicle", width: "20%" },
                      { Header: "type", accessor: "type", width: "10%" },
                      { Header: "mileage", accessor: "mileage", width: "10%" },
                      { Header: "fixed costs", accessor: "fixedcosts", width: "15%" },
                      { Header: "operating costs", accessor: "operatingcosts", width: "15%" },
                      { Header: "count", accessor: "count", width: "10%" },
                      { Header: "delete", accessor: "delete", width: "10%" },
                    ],
                    rows: vehicles,
                  }}
                />
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

// // // Setting default values for the props of Invoice
// // AddFleet.defaultProps = {
// //     noGutter: false,
// //   };

//   // Typechecking props for the Invoice
//   AddFleet.propTypes = {
//     date: PropTypes.string.isRequired,
//     id: PropTypes.string.isRequired,
//     price: PropTypes.string.isRequired,
//     noGutter: PropTypes.bool,
//   };
