/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import EditIcon from '@mui/icons-material/Edit';

// Soft UI Dashboard React components
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from 'react'

// Billing page components
import PaymentMethod from "layouts/adoptioncalc/components/PaymentMethod";
import Invoices from "layouts/adoptioncalc/components/Invoices";
import BillingInformation from "layouts/adoptioncalc/components/BillingInformation";
import Transactions from "layouts/adoptioncalc/components/Transactions";
import { Card, Icon, Tooltip, Typography } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import costsData from "./data.js";
import AddFleet from "./components/AddFleet/index.js";
import DataTable from "examples/Tables/DataTable/index.js";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart/index.js";
import { ObjectSchema } from "yup";
function AdoptionCalc() {

  const [open, setOpen] = React.useState(false)
  const [currentFleet, setCurrentFleet] = React.useState({name: 'Current Fleet'})
  const [fleet1, setFleet1] = React.useState({name: 'Comparison Fleet 1'})
  const [fleet2, setFleet2] = React.useState({name: 'Comparison Fleet 2'})
  const [editFleet, setEditFleet] = React.useState({})
  const [selectedFleet, setSelectedFleet] = React.useState(currentFleet)
  const [fleetFunc, setFleetFunc] = React.useState(1)

  React.useEffect(()=>{
    if (selectedFleet.name === "Current Fleet"){
      setSelectedFleet(currentFleet)
    }
    
  }, [currentFleet])

  React.useEffect(()=>{
    if (selectedFleet.name === "Comparison Fleet 1"){
      setSelectedFleet(fleet1)
    }
    
  }, [fleet1])

  React.useEffect(()=>{
    if (selectedFleet.name === "Comparison Fleet 2"){
      setSelectedFleet(fleet2)
    }
    
  }, [fleet2])

  const startNew = () =>{
    setFleet1({name: 'Comparison Fleet 1'})
    setFleet2({name: 'Comparison Fleet 2'})
    setCurrentFleet({name: 'Current Fleet'})
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <AddFleet open={open} setOpen={setOpen} fleet={selectedFleet} setCurrentFleet={setCurrentFleet} setFleet1={setFleet1} setFleet2={setFleet2}/>

        <Grid item md={12}>
          <Card style={{ padding: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div><SoftTypography variant="h6">Adoption Calculator</SoftTypography>
                <SoftTypography variant="body2">Adoption Calculator gives financial analysis of Top 3 fleet configurations specified by users.
                </SoftTypography>
                <SoftTypography variant="body2">The total cost is an added value of -  ownership of vehicle, maintence cost , charging costs, fuel economy, incentives and grants.</SoftTypography>
              </div>
              <div>
                <SoftButton variant='gradient' color='success' style={{ marginBottom: '10px' }} onClick={()=>{startNew()}}>Start New Calculation</SoftButton>
                <br />
                <Tooltip title="Coming Soon">
                <SoftButton  variant="outlined" color="secondary">Save  Calculation</SoftButton>
                </Tooltip>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item md={12}>
          <Card style={{ padding: '20px' }}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <div><SoftTypography variant="h6">Financial Analysis - EV Adoption</SoftTypography>
                  <SoftTypography variant="body2">Cost Benefit Analysis
                  </SoftTypography>
                </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {Object.keys(currentFleet).length !== 1 &&
                  <div style={{padding: '20px', flex: 1}}>
                  <SoftBox bgColor="light" style={{padding: '10px 30px 10px 30px', borderRadius: '8px'}}>

                    <SoftTypography variant="body3" style={{textAlign: 'center', alignSelf: 'center'}}>Total Cost of Ownership</SoftTypography>
                    <SoftBox bgColor="success" style={{borderRadius: '8px', padding: '1px', marginBottom: '5px'}}><SoftTypography color="white" variant="body1" style={{textAlign: 'center', marginTop: '5px'}}>${currentFleet.annualFixed + currentFleet.annualOperating}/year</SoftTypography>
                    
                    </SoftBox>
                    <SoftTypography variant="body2">Fixed Costs: ${currentFleet.annualFixed}/year</SoftTypography>
                    <SoftTypography variant="body2">Operating Costs: ${currentFleet.annualOperating}/year</SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="h5" style={{marginTop: '20px'}}>Fleet 1: Current Profile</SoftTypography>
                  <SoftTypography variant="body2">This profile is the current operating fleet at your firm.</SoftTypography>
                  <div style={{display: 'flex', alignContent: 'center', alignItems: 'center', marginTop: '10px'}}>

                    <SoftButton variant="outlined" color="success" onClick={()=>setSelectedFleet(currentFleet)}>View Details</SoftButton>
                    <SoftButton variant="text" color="dark" onClick={()=>{setSelectedFleet(currentFleet); setOpen(true)}} style={{textAlign: 'center'}}><EditIcon style={{marginRight: '3px'}} /> Edit</SoftButton>
                  </div>
                  </div> }{Object.keys(currentFleet).length === 1 && <div style={{padding: '20px', flex: 1}}><SoftBox  onClick={()=>{setSelectedFleet(currentFleet); setOpen(true)}} style={{cursor: 'pointer', backgroundColor: 'white', padding: '10px 30px 10px 30px', borderRadius: '8px', minHeight: '15em', border: '1.5px dashed', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}>
    <Icon fontSize="large" style={{marginBottom: '20px'}}>add_circle_outline</Icon>

    <SoftTypography variant="h5">Add Current Fleet</SoftTypography>
 </SoftBox> </div>}
{Object.keys(fleet1).length !== 1 && 
                  <div style={{padding: '20px', flex: 1}}>
                  <SoftBox bgColor="light" style={{padding: '10px 30px 10px 30px', borderRadius: '8px'}}>

                  <SoftTypography variant="body3" style={{textAlign: 'center', alignSelf: 'center'}}>Total Cost of Ownership</SoftTypography>
                    <SoftBox bgColor="success" style={{borderRadius: '8px', padding: '1px', marginBottom: '5px'}}><SoftTypography color="white" variant="body1" style={{textAlign: 'center', marginTop: '5px'}}>${fleet1.annualFixed + fleet1.annualOperating}/year</SoftTypography>
                    
                    </SoftBox>
                    <SoftTypography variant="body2">Fixed Costs: ${fleet1.annualFixed}/year</SoftTypography>
                    <SoftTypography variant="body2">Operating Costs: ${fleet1.annualOperating}/year</SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="h5" style={{marginTop: '20px'}}>Fleet 2: Comparison Profile</SoftTypography>
                  <SoftTypography variant="body2">This profile is the first comparison fleet.</SoftTypography>
                  <div style={{display: 'flex', alignContent: 'center', alignItems: 'center', marginTop: '10px'}}>

                    <SoftButton variant="outlined" color="success" onClick={()=>setSelectedFleet(fleet1)}>View Details</SoftButton>
                    <SoftButton variant="text" color="dark" style={{textAlign: 'center'}} onClick={()=>{setSelectedFleet(fleet1); setOpen(true)}}><EditIcon style={{marginRight: '3px'}}/> Edit</SoftButton>
                  </div>
                  </div>
}
{Object.keys(fleet1).length === 1 && <div style={{padding: '20px', flex: 1}}><SoftBox  onClick={()=>{setSelectedFleet(fleet1); setOpen(true)}} style={{cursor: 'pointer', backgroundColor: 'white', padding: '10px 30px 10px 30px', borderRadius: '8px', minHeight: '15em', border: '1.5px dashed', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}>
    <Icon fontSize="large" style={{marginBottom: '20px'}}>add_circle_outline</Icon>

    <SoftTypography variant="h5">Add Comparison Fleet 1</SoftTypography>
 </SoftBox> </div>}
 {Object.keys(fleet2).length !== 1 && 
                  <div style={{padding: '20px', flex: 1}}>
                  <SoftBox bgColor="light" style={{padding: '10px 30px 10px 30px', borderRadius: '8px'}}>

                  <SoftTypography variant="body3" style={{textAlign: 'center', alignSelf: 'center'}}>Total Cost of Ownership</SoftTypography>
                    <SoftBox bgColor="success" style={{borderRadius: '8px', padding: '1px', marginBottom: '5px'}}><SoftTypography color="white" variant="body1" style={{textAlign: 'center', marginTop: '5px'}}>${fleet2.annualFixed + fleet2.annualOperating}/year</SoftTypography>
                    
                    </SoftBox>
                    <SoftTypography variant="body2">Fixed Costs: ${fleet2.annualFixed}/year</SoftTypography>
                    <SoftTypography variant="body2">Operating Costs: ${fleet2.annualOperating}/year</SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="h5" style={{marginTop: '20px'}}>Fleet 3: Comparison Profile</SoftTypography>
                  <SoftTypography variant="body2">This profile is the second comparison fleet.</SoftTypography>
                  <div style={{display: 'flex', alignContent: 'center', alignItems: 'center', marginTop: '10px'}}>

                    <SoftButton variant="outlined" color="success" onClick={()=>setSelectedFleet(fleet2)}>View Details</SoftButton>
                    <SoftButton variant="text" color="dark" style={{textAlign: 'center'}} onClick={()=>{setSelectedFleet(fleet2); setOpen(true)}}><EditIcon style={{marginRight: '3px'}}/> Edit</SoftButton>
                  </div>
                  </div>
}
{Object.keys(fleet2).length === 1 && <div style={{padding: '20px', flex: 1}}><SoftBox  onClick={()=>{setSelectedFleet(fleet2); setOpen(true)}} style={{cursor: 'pointer', backgroundColor: 'white', padding: '10px 30px 10px 30px', borderRadius: '8px', minHeight: '15em', border: '1.5px dashed', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}>
    <Icon fontSize="large" style={{marginBottom: '20px'}}>add_circle_outline</Icon>

    <SoftTypography variant="h5">Add Comparison Fleet 2</SoftTypography>
 </SoftBox> </div>}
              
              </div>



            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
              <Transactions fleet={selectedFleet}/>
            </Grid>
            <Grid item xs={12} lg={7} >
              <Card style={{paddingTop: '20px'}}>
                { Object.keys(currentFleet).length + Object.keys(fleet1).length + Object.keys(fleet2).length >3 ?
                <VerticalBarChart
                 title="Fleets Financial Comparison "
                 chart={{
                   labels: ["Total Cost (1st year)", "Monthly Operating Cost", "Monthly Fixed Cost (1st year)", "Total Cost (5 Years)"],
                   datasets: [{
                     label: "Current Fleet",
                     color: "dark",
                     data: [currentFleet? currentFleet.annualFixed + currentFleet.annualOperating : undefined, currentFleet? Math.round((currentFleet.annualOperating/12)*100)/100 : undefined, currentFleet? Math.round((currentFleet.annualFixed/12)*100)/100 : undefined, currentFleet?currentFleet.annualFixed*5 + currentFleet.annualOperating*5 : undefined],
                     stack: "Stack 0"
                   },
                   {
                    label: "Comparison Fleet 1",
                    color: "success",
                    data: [fleet1? fleet1.annualFixed + fleet1.annualOperating : undefined, fleet1? Math.round((fleet1.annualOperating/12)*100)/100 : undefined, fleet1? Math.round((fleet1.annualFixed/12)*100)/100 : undefined, fleet1?fleet1.totalMSRP + fleet1.annualOperating*5 : undefined],
                    stack: "Stack 1"
                  },
                  {
                    label: "Comparison Fleet 2",
                    color: "light",
                    data: [fleet2? fleet2.annualFixed + fleet2.annualOperating : undefined, fleet1? Math.round((fleet2.annualOperating/12)*100)/100 : undefined, fleet2? Math.round((fleet2.annualFixed/12)*100)/100 : undefined, fleet2?fleet2.totalMSRP + fleet2.annualOperating*5 : undefined],
                    stack: "Stack 2"
                  }
                  ],
                 }}
                 style={{paddingBottom: '20px'}}
                />:<SoftBox style={{cursor: 'pointer', backgroundColor: 'white', margin: '0 20px 20px 20px', borderRadius: '8px', minHeight: '15em', border: '1.5px dashed', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}> <SoftTypography variant="h5">Add a fleet to see comparison</SoftTypography></SoftBox>
}
           </Card>
            </Grid>
      </Grid>
      <div style={{height: '20px'}}/>
      <Footer />
    </DashboardLayout>
  );
}

export default AdoptionCalc;
