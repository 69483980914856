/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const barGraphData = {
  labels: ["October", "November", "December", "January", "February", "March"],
  datasets: [
    {
      label: "Total Emission Savings",
      color: "dark",
      data: [70, 70, 72, 72, 73, 76],
    },
    {
      label: "Total Cost Savings",
      color: "secondary",
      data: [55, 56, 57, 59, 59, 62],
    },
  ],
};

export default barGraphData;
