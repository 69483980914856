import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

function configs(labels, datasets) {
  return {
    data: {
      labels,
      datasets: datasets.map((dataset) => ({
        ...dataset,
        borderColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        backgroundColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        fill: false,
      })),
    },
    options: {
      interaction: {
        intersect: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            boxHeight: 20,
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Date',
            font: {
              size: 16,
              weight: 'bold'
            }
          }
        },
        y: {
          title: {
            display: true,
            text: 'Energy Cost',
            font: {
              size: 16,
              weight: 'bold'
            }
          }
        }
      }
    },
  };
}

export default configs;
