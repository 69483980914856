/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const gradientLineChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  datasets: [
    {
      label: "Actual Spending",
      color: "info",
      data: [5000, 4000, 3000, 2200, 5000, 2500, 4000, 2300, 5000],
    },
    {
      label: "Planned Spending",
      color: "dark",
      data: [3000, 9000, 4000, 1400, 2900, 2900, 3400, 2300, 4000],
    },
  ],
};

export default gradientLineChartData;
