/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import EVProfile from "layouts/ev-profile";
import AdoptionCalc from "layouts/adoptioncalc";
import VirtualReality from "layouts/virtual-reality";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Activate from "layouts/authentication/activate";
import ICEProfile from "layouts/ice-profile";
import PasswordReset from "layouts/authentication/password-reset";
import ForgotPassword from "layouts/authentication/forgot-password";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";
import Basket from "examples/Icons/Basket";
import Icon from "@mui/material/Icon";
import OptefyDemo from "layouts/chargedemo";
import Forecast from "layouts/forecast";
import ChargingStatus from "layouts/chargingstatus";
import LandingPage from "layouts/landing-page";
import { Bar } from "react-chartjs-2";
import HistoryPage from "layouts/history";
import AnalysisPage from "layouts/analysis";
import LoadManagementPage from "layouts/load-management";

const routes = [
  {
    type: "collapse",
    name: "Solv4x Launchpad",
    key: "solv4x-launchpad",
    route: "/launchpad",
    icon: <Document size="12px" />,
    component: <LandingPage />,
    noCollapse: true,
    App: ["launchpad"],
    noNav: false,
    protected: true,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/fleetefy",
    App: ["fleetefy"],
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
    demo: true,
    protected: true, //************* CHANGED TO PROTECTED *************
  },
  {
    type: "collapse",
    name: "EV Profile",
    key: "evprofile",
    App: ["fleetefy"],
    route: "/fleetefy/evprofile",
    icon: <Office size="12px" />,
    component: <EVProfile />,
    noCollapse: true,
    demo: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "ICE Profile",
    key: "iceprofile",
    App: ["fleetefy"],
    route: "/fleetefy/iceprofile",
    icon: <Office size="12px" />,
    component: <ICEProfile />,
    noCollapse: true,
    demo: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Adoption",
    key: "adoption",
    App: ["fleetefy"],
    route: "/fleetefy/adoption",
    icon: <CreditCard size="12px" />,
    component: <AdoptionCalc />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    App: ["launchpad"],
    route: "/launchpad/profile",
    icon: <Icon>account_circle</Icon>,
    component: <Profile />,
    noCollapse: true,
    protected: true,
  },
  { type: "title", title: "Analytics Pages", key: "account-pages", App: ["fleetefy"] },
  {
    type: "collapse",
    name: "Financial",
    key: "financial",
    App: ["fleetefy"],
    route: "#",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
    soon: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Electrical",
    key: "electrical",
    App: ["fleetefy"],
    route: "#",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
    soon: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
    App: [],
    noNav: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <Document size="12px" />,
    component: <SignUp />,
    noCollapse: true,
    App: [],
    noNav: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "Activate",
    key: "activate",
    route: "/authentication/activate/:uid/:token",
    icon: <Document size="12px" />,
    component: <Activate />,
    noCollapse: true,
    App: [],
    noNav: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/optefy",
    App: ["optefy"],
    icon: <Shop size="12px" />,
    component: <OptefyDemo />,
    noCollapse: true,
    demo: false,
    protected: true,
  },
  {
    type: "collapse",
    name: "History",
    key: "History",
    route: "/optefy/history",
    App: ["optefy"],
    icon: <CreditCard size="12px" />,
    component: <HistoryPage />,
    noCollapse: true,
    demo: false,
    protected: true,
  },
  {
    type: "collapse",
    name: "Analysis",
    key: "Analysis",
    route: "/optefy/analysis",
    App: ["optefy"],
    icon: <Cube size="12px" />,
    component: <AnalysisPage />,
    noCollapse: true,
    demo: false,
    protected: true,
  },
  {
    type: "collapse",
    name: "Load Management",
    key: "load-management",
    route: "/optefy/load-management",
    App: ["optefy"],
    icon: <Basket size="12px" />,
    component: <LoadManagementPage />,
    noCollapse: true,
    demo: false,
    protected: true,
  },
  {
    type: "collapse",
    name: "Forecast",
    key: "forecast",
    route: "/optefy/forecast",
    App: [],
    icon: <Shop size="12px" />,
    component: <Forecast />,
    noCollapse: true,
    demo: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Charging Status",
    key: "chargingstatus",
    App: [],
    route: "/optefy/chargingstatus",
    icon: <Office size="12px" />,
    component: <ChargingStatus />,
    noCollapse: true,
    demo: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Password Reset",
    key: "password-reset",
    route: "/password/reset/confirm/:uid/:token",
    icon: <Document size="12px" />,
    component: <PasswordReset />,
    noCollapse: true,
    App: [],
    noNav: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    route: "/authentication/forgot-password",
    icon: <Document size="12px" />,
    component: <ForgotPassword />,
    noCollapse: true,
    App: [],
    noNav: true,
    protected: false,
  },
];

export default routes;
