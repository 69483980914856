/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";

// Images
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoWebDev from "assets/images/small-logos/logo-webdev.svg";
import logoXD from "assets/images/small-logos/logo-xd.svg";

import chargepoint from "assets/images/logos/chargepoint.png";
import abb from "assets/images/logos/abb.png";
import flo from "assets/images/logos/flo.png";
import alectra from "assets/images/logos/alectra.png";

function Completion({ value, color }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {value}%&nbsp;
      </SoftTypography>
      <SoftBox width="8rem">
        <SoftProgress value={value} color={color} variant="gradient" label={false} />
      </SoftBox>
    </SoftBox>
  );
}

const action = (
  <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
    more_vert
  </Icon>
);

const projectsTableData = {
  columns: [
    { name: "company", align: "left" },
    { name: "component", align: "left" },
    { name: "count", align: "left" },
    { name: "budget", align: "left" },
    { name: "completion", align: "center" },
    
    { name: "action", align: "center" }
    
  ],

  rows: [
    {
      company: [chargepoint, "Chargepoint"], count: 6,
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $22,500
        </SoftTypography>
      ),
      component: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          Charger
        </SoftTypography>
      ),
      completion: <Completion value={60} color="info" />,
      action,
    },
    {
      company: [abb, "ABB"], count: 15,
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $51,000
        </SoftTypography>
      ),
      component: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          Connectors
        </SoftTypography>
      ),
      completion: <Completion value={100} color="success" />,
      action,
    },
    {
      company: [flo, "Flo"], count: 14,
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $32,400
        </SoftTypography>
      ),
      component: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          Charger
        </SoftTypography>
      ),
      completion: <Completion value={30} color="error" />,
      action,
    },
    {
      company: [alectra, "Alectra"],count: 11,
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $113,400
        </SoftTypography>
      ),
      component: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          Utility Permit
        </SoftTypography>
      ),
      completion: <Completion value={0} color="error" />,
      action,
    },
   
  ],
};

export default projectsTableData;
