/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import "assets/theme/loading.css"

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { BACKEND_URL } from "configs";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import trees from "assets/images/curved-images/trees.jpeg";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedin] = React.useState(false);
  const [signInLoading, setSignInLoading] = useState(false);

  React.useEffect(()=>{
    async function updateLogged() {
    try {
      const access_token = localStorage.getItem('access_token');
      let res = await fetch(BACKEND_URL + "/auth/users/me/", {
        method: "GET",
        headers: {
          'Authorization' : 'JWT ' + access_token
          }
      });
      if (res.status === 200) {
        console.log("Logged in");
        
        setLoggedin(true)
        setLoading(false);
      } else {
        console.log("Not logged in", res);
        setLoggedin(false)
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } }
    updateLogged();
  }, [])

  if(loggedIn)
  {
    window.location.href = "/launchpad";
  }

  let handleSubmission = async () => {

    setMessage("");
    if (isValidEmail(email)) {
      setSignInLoading(true)
      try {
        let res = await fetch(BACKEND_URL + "/auth/jwt/create/", { // use other let statement when backend is working properly
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
            },
          body: JSON.stringify({
            email: email,
            password: password
          }),
        });
        let resJson = await res.json();
        setSignInLoading(false)
        if (res.status === 200) {
          localStorage.setItem('username', email);
          localStorage.setItem('access_token', resJson.access);
          localStorage.setItem('refresh_token', resJson.refresh);
          setEmail("");
          setPassword("");
          //setMessage("Logging in...");
          window.location.href = "/launchpad";
        } else {
          setMessage("Email or password is incorrect");
        }
      } catch (err) {
        console.log(err);
      }
    }
    else {
      setMessage("Email is invalid");
    }
  };
  function isValidEmail(email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }


  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <CoverLayout
      title="Login to Solv4x Platform"
      description="Enter your email and password to sign in"
      image={trees}
      color={"success"}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold" >
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" placeholder="Email" value={email} onKeyDown={(e)=>{if (e.key === 'Enter') {
      handleSubmission()
    }}} onChange={(e) => setEmail(e.target.value)} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput type="password" placeholder="Password" value={password} onKeyDown={(e)=>{if (e.key === 'Enter') {
      handleSubmission()
    }}} onChange={(e) => setPassword(e.target.value)} />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="success" fullWidth onClick={handleSubmission}>
            {signInLoading ? 
            <div className="loader">
            <div className="inner one"></div>
            <div className="inner two"></div>
            <div className="inner three"></div>
          </div>: "sign in"
}
          </SoftButton>
        </SoftBox>
        {message &&
          <SoftTypography
            color="error"
            variant="body2"
          >{message}</SoftTypography>
        }
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="dark"
              fontWeight="medium"
              textGradient
            >
              Sign Up
            </SoftTypography>
            <br></br>
            Forgot your password?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/forgot-password"
              variant="button"
              color="dark"
              fontWeight="medium"
              textGradient
            >
              Reset Your Password
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
