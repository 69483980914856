/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import trees from "assets/images/curved-images/trees.jpeg";

import 'assets/theme/loading.css';
import { BACKEND_URL } from "configs";

function SignUp() {


  var dict = []; // create an empty array   <h2 style={{color: 'green', 'font-size': '16px'}}>


  const [agreement, setAgremment] = useState(true);
  const [email, setEmail] = useState('');
  const [emailError, setemailError] = useState(null);
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [firstNameError, setfirstNameError] = useState(null);
  const [lastNameError, setlastNameError] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordError, setpasswordError] = useState(null);
  const [password2, setPassword2] = useState('');
  const [passwordError2, setpasswordError2] = useState(null);
  const [message, setMessage] = useState(null);
  const [companyCode, setcompanyCode] = useState('');
  const [companyCodeError, setcompanyCodeError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleSetAgremment = () => setAgremment(!agreement);
  const handleSubmit = () => {
    console.log("CLOCKED")
    var badEmail, badFirstName, badLastName, badCompany, badPassword
    if (!isValidEmail(email)) {
      setemailError('Email is invalid');
      badEmail = true
    } else {
      badEmail = false
      setemailError("");
    }
    if (firstName.length < 1) {
      setfirstNameError("First name is required");
      badFirstName = true
    }
    else {
      setfirstNameError("");
      badFirstName = false
    }
    if (lastName.length < 1) {
      setlastNameError("Last name is required");
      badLastName = true
    }
    else {
      setlastNameError("");
      badLastName = false
    }

    if (password != password2) {
      setpasswordError("Passwords do not match");
      setpasswordError2("Passwords do not match");
      badPassword = true
    }
    else if (password.length < 8) {
      setpasswordError("Password must be at least 8 characters");
      setpasswordError2("Password must be at least 8 characters");
      badPassword = true
    }
    else {
      setpasswordError("");
      setpasswordError2("");
      badPassword = false
    }
    if (companyCode.length < 1) {
      setcompanyCodeError("Company Code is required");
      badCompany = true
    }
    else {
      setcompanyCodeError("");
      badCompany = false

    }
    if (!badEmail && !badFirstName && !badLastName && !badPassword && !badCompany) {
      setLoading(true)
      handleSubmission();
    }
  };

  let handleSubmission = async () => {
    setMessage("");
    try {
      let res = await fetch(BACKEND_URL + "/auth/users/", { // use other let statement when backend is working properly
      // let res = await fetch("http://django-backend-env-1-backup.us-west-2.elasticbeanstalk.com/auth/users/", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
          },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          re_password: password2,
          company_code: companyCode,
        }),
      });
      let resJson = await res.json();
      setLoading(false)
      if (res.status === 201) {
        setfirstName("");
        setlastName("");
        setEmail("");
        setPassword("");
        setPassword2("");
        setcompanyCode("");
        setMessage("Account created successfully. Check your email for activation link.");
        // window.location.href = "/sign-in";
      } else {
        // setMessage("Some error occured");
        if (resJson.email){
          setemailError(resJson.email);
        }
        if (resJson.password){
          setpasswordError(resJson.password);
          setpasswordError2(resJson.password);
        }
        if (resJson.company_code){
          setcompanyCodeError("Company code is invalid");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  function isValidEmail(email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }



  return (

    <CoverLayout
      title="Welcome!"
      description="Please register by filling in the following fields."
      image={trees}
      color={"success"}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Register your account
          </SoftTypography>
        </SoftBox>

        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            {firstNameError &&
              <SoftTypography
                color="error"
                variant="body2"
              >{firstNameError}</SoftTypography>
            }
            <SoftBox mb={2}>
              <SoftInput placeholder="First Name" name="name" value={firstName} onChange={(e) => setfirstName(e.target.value)} />
            </SoftBox>
            {lastNameError &&
              <SoftTypography
                color="error"
                variant="body2"
              >{lastNameError}</SoftTypography>
            }
            <SoftBox mb={2}>
              <SoftInput placeholder="Last Name" name="name" value={lastName} onChange={(e) => setlastName(e.target.value)} />
            </SoftBox>
            {emailError &&
              <SoftTypography
                color="error"
                variant="body2"
              >{emailError}</SoftTypography>
            }
            <SoftBox mb={2}>
              <SoftInput name="email" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </SoftBox>

            {passwordError &&
              <SoftTypography
                color="error"
                variant="body2"
              >{passwordError}</SoftTypography>
            }
            <SoftBox mb={2}>
              <SoftInput type="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </SoftBox>
            {passwordError2 &&
              <SoftTypography
                color="error"
                variant="body2"
              >{passwordError2}</SoftTypography>
            }
            <SoftBox mb={2}>
              <SoftInput type="password" name="password2" placeholder="Confirm Password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
            </SoftBox>

            {companyCodeError &&
              <SoftTypography
                color="error"
                variant="body2"
              >{companyCodeError}</SoftTypography>
            }
            <SoftBox mb={2}>
              <SoftInput type="text" placeholder="Company Code" value={companyCode} onChange={(e) => setcompanyCode(e.target.value)} />
            </SoftBox>

            <SoftBox display="flex" alignItems="center">
              <Checkbox checked={agreement} onChange={handleSetAgremment} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgremment}
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                textGradient
              >
                Terms and Conditions
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="success" fullWidth onClick={handleSubmit}>
                {isLoading ?  <div className="loader">
  <div className="inner one"></div>
  <div className="inner two"></div>
  <div className="inner three"></div>
</div>: "sign up"}
              </SoftButton>
            </SoftBox>
            {message &&
              <SoftTypography
                color="dark"
                variant="body2"
                fontWeight="medium"
                style={{marginTop: "10px", textAlign: "center"}}
              >{message}</SoftTypography>
            }
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Already have an account?&nbsp;
                <SoftTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign In
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </CoverLayout>
  );

}

export default SignUp;
