/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import TablePagination from "@material-ui/core/TablePagination";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import Kia from "assets/images/logos/kia.png";
import Tesla from "assets/images/logos/tesla.png";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VerticalBarChart from "./components/VerticalBarChart";
import LineGraphChart from "./components/LineGraph"
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import vehiclesTable from "./data/chargingTable";
import SoftInput from "components/SoftInput";
import React from "react";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import { Divider } from "@mui/material";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Grid from "@mui/material/Grid";
import KiaEV from "assets/images/cars/kia_soul.png";
import teslaEV from "assets/images/cars/tesla_model_3.png";
import chargingTable from "./data/chargingTable";
import SoftAvatar from "components/SoftAvatar";
import { BACKEND_URL } from "configs";
import "./analysis.css";
import { FiArrowRight } from "react-icons/fi";

// Data
import barGraphData from "./data/barGraphData";
import LineGraphData from "./data/lineGraphData";

function AnalysisPage() {
  const [vehicles, setVehicles] = React.useState([]);

  const [vehicleOptions, setVehicleOptions] = React.useState([
    { value: "all", label: "All" },
    { value: "kia1", label: "Kia GVDC701" },
    { value: "kia2", label: "Kia GVDC702" },
  ]);

  let currentMonth = new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date());
  let currentYear = new Intl.DateTimeFormat("default", { year: "numeric" }).format(new Date());

  const [vehicleMap, setVehicleMap] = React.useState({});

  const { columns, rows } = chargingTable;

  const [selectedVehicle, setSelectedVehicle] = React.useState({});
  const [vehicleLog, setVehicleLog] = React.useState({ value: "all", label: "All" });

  const [chargeRows, setChargeRows] = React.useState([]);
  const [displayedRows, setDisplayedRows] = React.useState([]);

  const [savingsRow, setSavingsRow] = React.useState(["N/A", "N/A", "N/A", "N/A"]);
  const [badgeRow, setBadgeRow] = React.useState(["N/A", "N/A", "N/A", "N/A"]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function Author(image, name, email) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium" style={{ textTransform: "none" }}>
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  function makeDateReadable(unixDate, one_line) {
    const milliseconds = unixDate * 1000;
    const dateObject = new Date(milliseconds);

    const humanDateFormat = dateObject.toLocaleString();

    let weekday = dateObject.toLocaleString("en-US", { weekday: "long" }); // Monday
    let month = dateObject.toLocaleString("en-US", { month: "long" }); // December
    let date = dateObject.toLocaleString("en-US", { day: "numeric" }); // 9
    let year = dateObject.toLocaleString("en-US", { year: "numeric" }); // 2019
    let time = new Date(unixDate * 1000).toLocaleTimeString("en-US");

    if (one_line) {
      return month + " " + date + " " + year + ", " + time;
    } else {
      return month + " " + date + " " + year, time;
    }
  }
  React.useEffect(() => {
    async function fetchData() {
      const access_token = localStorage.getItem("access_token");
      let res = await fetch(BACKEND_URL + "/summary/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + access_token,
        },
      });
      if (res.status == 200) {
        let response_json = await res.json();
        let new_mapping = [
          response_json[0].cost_savings + "%",
          response_json[0].emissions_savings + "%",
          response_json[0].battery_efficiency + "%",
          "50-80%",
        ];

        let new_badges = [
          "+9.3% This Month",
          "+4.5% This Month",
          "+8.2% This Month",
          "Same as Last Month",
        ];
        setSavingsRow(new_mapping);
        setBadgeRow(new_badges);
      }
    }
    fetchData();
  }, []);
  React.useEffect(() => {
    async function fetchData() {
      const access_token = localStorage.getItem("access_token");
      let new_vehicle_map = {};
      let res = await fetch(BACKEND_URL + "/vehicles/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + access_token,
        },
      });
      if (res.status == 200) {
        let response_json = await res.json();
        let new_rows = response_json.map((e) => {
          return { ...e, value: e.vin, label: e.make + " " + e.alias };
        });

        new_rows.forEach((element) => {
          new_vehicle_map[element.id] = element;
        });

        setVehicleMap(new_vehicle_map);

        let new_chargelog_vehicles = [{ value: "All", label: "All" }].concat(new_rows);
        setVehicleOptions(new_chargelog_vehicles);
        setVehicles(new_rows);

        setSelectedVehicle(new_rows[0]);
      }

      res = await fetch(BACKEND_URL + "/cycles/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + access_token,
        },
      });

      if (res.status == 200) {
        let response_json = await res.json();
        let new_rows = response_json.map((e) => {
          const dateObject = new Date(e.start_time * 1000);  // use start_time since stop_time is sometimes null
          let month = dateObject.toLocaleString("en-US", { month: "long" });
          let logoImage;
          if (new_vehicle_map[e.vehicle].make === "Tesla") {
            logoImage = Tesla;
          } else if (new_vehicle_map[e.vehicle].make === "Kia") {
            logoImage = Kia;
          }
          const arrowStyle = { // used for arrow icon
            fontSize: "14px",
            color: "#A0B0D0",
            verticalAlign: "middle",
          };
          let numHours = Math.round(((e.stop_time - e.start_time) / 3600) * 10, 1) / 10; // length of charge cycle in hours
          return {
            id: new_vehicle_map[e.vehicle].vin,
            vehicle: Author(
              logoImage,
              new_vehicle_map[e.vehicle].make,
              new_vehicle_map[e.vehicle].alias
            ),
            month: month,
            "start time": (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {new Date(e.start_time * 1000).toLocaleTimeString("en-US")} <br />
                {new Date(e.start_time * 1000).toLocaleDateString("en-US")}
              </SoftTypography>
            ),
            "stop time": e.stop_time !== null ? (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {new Date(e.stop_time * 1000).toLocaleTimeString("en-US")} <br />
                {new Date(e.stop_time * 1000).toLocaleDateString("en-US")}
              </SoftTypography>
            ) : (
            <SoftTypography style={{ textAlign: "center" }} variant="caption">In progress</SoftTypography>
            ),
            "energy rate": e.energy_rate !== null ? (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.energy_rate}
              </SoftTypography>
            ) : (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">N/A</SoftTypography>
            ),
            /*
            "start battery": (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.soc_start}%
              </SoftTypography>
            ),
            "stop battery": (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.soc_stop}%
              </SoftTypography>
            ),
            */
            "battery": e.soc_stop !== null ? (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.soc_start}% <FiArrowRight style={{ ...arrowStyle }} />  {e.soc_stop}%
              </SoftTypography>
            ) : (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.soc_start}% -{'>'} N/A
              </SoftTypography>
            ),
            "charging speed": e.soc_stop !== null ? (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {Math.round(((e.soc_stop - e.soc_start) / numHours)*10) / 10}%/h
              </SoftTypography>
            ) : (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">N/A</SoftTypography>
            ),
            odometer: (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.odometer} Km
              </SoftTypography>
            ),

            optefied: e.optefied_flag ? (
              <CheckIcon fontSize="medium" color="success" />
            ) : (
              <ClearIcon fontSize="medium" color="error" />
            ),
          };
        });
        new_rows = new_rows.filter((e) => e.month === currentMonth);
        setChargeRows(new_rows);
        setDisplayedRows(new_rows);
      }
    }
    fetchData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <div style={{ display: "flex", alignItems: "flex-end", justifyItems: "center" }}>
            <SoftTypography id="analysisTitle" variant="h2" style={{ marginBottom: "30px", marginRight: "10px" }}>
              Analysis{" "}
            </SoftTypography>
            <SoftTypography variant="h6" style={{ marginBottom: "30px" }}>
              {currentMonth} {currentYear}
            </SoftTypography>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <Card>
                <SoftBox p={3}>
                  <SoftTypography variant="body2" color="text" fontWeight="regular">
                    Cost Savings
                  </SoftTypography>
                  <SoftBox mt={2} mb={1} lineHeight={0}>
                    <SoftTypography className="savingsRowBox" variant="h3" fontWeight="bold" fontSize="1.875em" style={{ fontSize: "1.875em !important" }}>
                      {savingsRow[0]}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBadge
                    variant="contained"
                    color="success"
                    badgeContent={badgeRow[0]}
                    container
                  />
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <Card>
                <SoftBox p={3}>
                  <SoftTypography variant="body2" color="text" fontWeight="regular">
                    GHG Emission Savings
                  </SoftTypography>
                  <SoftBox mt={2} mb={1} lineHeight={0}>
                    <SoftTypography className="savingsRowBox" variant="h3" fontWeight="bold">
                      {savingsRow[1]}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBadge
                    variant="contained"
                    color="success"
                    badgeContent={badgeRow[1]}
                    container
                  />
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <Card>
                <SoftBox p={3}>
                  <SoftTypography variant="body2" color="text" fontWeight="regular">
                    Battery Efficiency
                  </SoftTypography>
                  <SoftBox mt={2} mb={1} lineHeight={0}>
                    <SoftTypography className="savingsRowBox" variant="h3" fontWeight="bold">
                      {savingsRow[2]}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBadge
                    variant="contained"
                    color="success"
                    badgeContent={badgeRow[2]}
                    container
                  />
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <Card>
                <SoftBox p={3}>
                  <SoftTypography variant="body2" color="text" fontWeight="regular">
                    Fleet Target Range
                  </SoftTypography>
                  <SoftBox mt={2} mb={1} lineHeight={0}>
                    <SoftTypography className="savingsRowBox" variant="h3" fontWeight="bold">
                      {savingsRow[3]}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBadge
                    variant="contained"
                    color="success"
                    badgeContent={badgeRow[3]}
                    container
                  />
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <SoftBox mb={3} style={{ marginTop: "40px", minHeight: "200px" }}>
        <VerticalBarChart title="Monthly Savings" chart={barGraphData}/>
      </SoftBox>
      <SoftBox>
      <LineGraphChart
        title="Energy Savings"
        description=""
        chart={LineGraphData}
      />
      </SoftBox>
      
      <Card></Card>
      <SoftBox mb={3} style={{ marginTop: "40px", minHeight: "200px" }}>
        <Card style={{ padding: "30px" }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
            <SoftTypography id="chargeLogsTitle" variant="h4" style={{ marginRight: "20px", marginTop: "-10px" }}>
              Charging Logs for {currentMonth}
            </SoftTypography>
            <SoftSelect
              placeholder="Select a vehicle"
              options={vehicleOptions}
              value={vehicleLog}
              onChange={(option) => {
                setVehicleLog(option);
                if (option.value === "All") {
                  setDisplayedRows(chargeRows.filter((e) => e.month === currentMonth));
                } else {
                  setDisplayedRows(
                    chargeRows.filter((e) => e.id === option.vin && e.month === currentMonth)
                  );
                }
              }}
            />
          </div>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table
              columns={columns}
              rows={displayedRows
                .filter((e) => e.month === currentMonth)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              page={page}
              rowsPerPage={rowsPerPage}
            />
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={displayedRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </SoftBox>
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default AnalysisPage;
