/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import SoftInput from "components/SoftInput";

import SoftButton from "components/SoftButton";

function Overview() {

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  return (


    <DashboardLayout>
      <Header />
      <SoftBox mt={5} mb={3} style={{ minHeight: "90vh" }}>
        <Grid container spacing={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid item xs={12} md={6} xl={6} style={{ marginTop: '5em' }}>
            <Card style={{ padding: "20px" }}>
              <SoftBox component="form" role="form" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                <SoftBox mb={2} >
                  <SoftTypography component="label" variant="h4" fontWeight="bold" style={{ marginBottom: "3em"}}>
                    Edit Profile
                  </SoftTypography>
                  <br />
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Name
                  </SoftTypography>
                  <SoftBox mb={2}>
                    <SoftInput placeholder={localStorage.getItem("username")} name="name" value={name} onChange={(e) => setName(e.target.value)}/>
                  </SoftBox>
                  <SoftTypography component="label" variant="caption" fontWeight="bold" style={{ marginBottom: "3em" }}>
                    Password
                  </SoftTypography>
                  <SoftBox mb={2}>
                    <SoftInput placeholder="New Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </SoftBox>
                  <SoftTypography component="label" variant="caption" fontWeight="bold" style={{ marginBottom: "3em" }}>
                    Confirm Password
                  </SoftTypography>
                  <SoftBox mb={2}>
                    <SoftInput placeholder="Confirm Password" name="password2" value={password2} onChange={(e) => setPassword2(e.target.value)} style={{ marginBottom: "1em" }} />
                    <SoftButton variant="gradient" color="success">
                      Update Profile
                    </SoftButton>
                  </SoftBox>

                </SoftBox>

              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>


      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
