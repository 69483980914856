/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const reportsBarChartData = {
  chart: {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Expenses", data: [1200, 3200, 5600, 3400,4500, 2000, 1000, 2200, 5000, 1000, 4000, 2300, 5000] },
  },
  items: [
    {
      icon: { color: "primary", component: "paid" },
      label: "funds",
      progress: { content: "$3M", percentage: 60 },
    },
    {
      icon: { color: "info", component: "paid" },
      label: "vehicle",
      progress: { content: "$600K", percentage: 90 },
    },
    {
      icon: { color: "warning", component: "paid" },
      label: "charger",
      progress: { content: "$435K", percentage: 30 },
    },
    {
      icon: { color: "error", component: "paid" },
      label: "infra",
      progress: { content: "$98K", percentage: 50 },
    },
    {
      icon: { color: "primary", component: "paid" },
      label: "funds",
      progress: { content: "$3M", percentage: 60 },
    },
    {
      icon: { color: "info", component: "paid" },
      label: "vehicle",
      progress: { content: "$600K", percentage: 90 },
    },
    {
      icon: { color: "warning", component: "paid" },
      label: "charger",
      progress: { content: "$435K", percentage: 30 },
    },
    {
      icon: { color: "error", component: "paid" },
      label: "infra",
      progress: { content: "$98K", percentage: 50 },
    },
  ],
};

export default reportsBarChartData;
