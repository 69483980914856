/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import Kia from "assets/images/logos/kia.png";
import Tesla from "assets/images/logos/tesla.png";

import CheckIcon from "@mui/icons-material/Check";
import Collapse from "@mui/material/Collapse";
import ClearIcon from "@mui/icons-material/Clear";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import SoftInput from "components/SoftInput";
import React from "react";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import { Divider, IconButton, TableBody } from "@mui/material";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Grid from "@mui/material/Grid";
import KiaEV from "assets/images/cars/kia_soul.png";
import chargingTable from "./data/chargingTable";
import summaryTable from "./data/summaryTable";
import SoftAvatar from "components/SoftAvatar";
import { BACKEND_URL } from "configs";
import { FiArrowRight } from "react-icons/fi";

function HistoryPage() {
  const [vehicles, setVehicles] = React.useState([
    { value: "kia1", label: "Kia GVDC701" },
    { value: "kia2", label: "Kia GVDC702" },
  ]);

  const [isCollapsed, setCollapsed] = React.useState(true);
  const [isCollapsed2, setCollapsed2] = React.useState(false);

  const [vehicleOptions, setVehicleOptions] = React.useState([
    { value: "all", label: "All" },
    { value: "kia1", label: "Kia GVDC701" },
    { value: "kia2", label: "Kia GVDC702" },
  ]);

  const [monthOptions, setMonthOptions] = React.useState([
    { value: "All", label: "All" },
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ]);

  const [summaryRows, setSummaryRows] = React.useState([]);

  const [vehicleMap, setVehicleMap] = React.useState({});
  const [monthLog, setMonthLog] = React.useState({ value: "All", label: "All" });
  const [vehicleLog, setVehicleLog] = React.useState({ value: "All", label: "All" });

  const { chargingColumns } = chargingTable;
  const { summaryColumns } = summaryTable;

  const [selectedVehicle, setSelectedVehicle] = React.useState({});

  const [chargeRows, setChargeRows] = React.useState([]);
  const [displayedRows, setDisplayedRows] = React.useState([]);

  function Author(image, name, email) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }
  React.useEffect(() => {
    async function fetchData() {
      const access_token = localStorage.getItem("access_token");
      let res = await fetch(BACKEND_URL + "/summary/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + access_token,
        },
      });
      if (res.status == 200) {
        let response_json = await res.json();
        let rows = response_json.map((row) => {
          return {
            Date: (
              <SoftTypography style={{ align: "right" }} variant="caption">
                {row.date}
              </SoftTypography>
            ),
            "Cost Savings": (
              <SoftTypography style={{ align: "right" }} variant="caption">
                {row.cost_savings}%
              </SoftTypography>
            ),
            "Emissions Savings": (
              <SoftTypography style={{ align: "right" }} variant="caption">
                {row.emissions_savings}%
              </SoftTypography>
            ),
            "Battery Efficiency": (
              <SoftTypography style={{ align: "right" }} variant="caption">
                {row.battery_efficiency}%
              </SoftTypography>
            ),
          };
        });

        setSummaryRows(rows);
      }
    }
    fetchData();
  }, []);
  React.useEffect(() => {
    async function fetchData() {
      const access_token = localStorage.getItem("access_token");
      let new_vehicle_map = {};
      let res = await fetch(BACKEND_URL + "/vehicles/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + access_token,
        },
      });
      if (res.status == 200) {
        let response_json = await res.json();
        let new_rows = response_json.map((e) => {
          return { ...e, value: e.vin, label: e.make + " " + e.alias };
        });

        new_rows.forEach((element) => {
          new_vehicle_map[element.id] = element;
        });

        setVehicleMap(new_vehicle_map);

        let new_chargelog_vehicles = [{ value: "All", label: "All" }].concat(new_rows);
        setVehicleOptions(new_chargelog_vehicles);
        setVehicles(new_rows);

        setSelectedVehicle(new_rows[0]);
      }

      res = await fetch(BACKEND_URL + "/cycles/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + access_token,
        },
      });

      if (res.status == 200) {
        let response_json = await res.json();
        let new_rows = response_json.map((e) => {
          const dateObject = new Date(e.start_time * 1000);  // use start_time since stop_time is sometimes null
          let month = dateObject.toLocaleString("en-US", { month: "long" }); // December
          let logoImage;
          if (new_vehicle_map[e.vehicle].make === "Tesla") {
            logoImage = Tesla;
          } else if (new_vehicle_map[e.vehicle].make === "Kia") {
            logoImage = Kia;
          }
          const arrowStyle = { // used for arrow icon
            fontSize: "14px",
            color: "#A0B0D0",
            verticalAlign: "middle",
          };

          return {
            id: new_vehicle_map[e.vehicle].vin,
            vehicle: Author(
              logoImage,
              new_vehicle_map[e.vehicle].make,
              new_vehicle_map[e.vehicle].alias
            ),
            month: month,
            "start time": (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {new Date(e.start_time * 1000).toLocaleTimeString("en-US")} <br />
                {new Date(e.start_time * 1000).toLocaleDateString("en-US")}
              </SoftTypography>
            ),
            "stop time": e.stop_time !== null ? (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {new Date(e.stop_time * 1000).toLocaleTimeString("en-US")} <br />
                {new Date(e.stop_time * 1000).toLocaleDateString("en-US")}
              </SoftTypography>
            ) : (
            <SoftTypography style={{ textAlign: "center" }} variant="caption">In progress</SoftTypography>
            ),
            /*
            "start battery": (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.soc_start}%
              </SoftTypography>
            ),
            "stop battery": e.soc_stop !== null ? (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.soc_stop}%
              </SoftTypography>
            ) : (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">N/A</SoftTypography>
            ),
            */
            "energy rate": e.energy_rate !== null ? (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.energy_rate}
              </SoftTypography>
            ) : (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">N/A</SoftTypography>
            ),
            "battery": e.soc_stop !== null ? (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.soc_start}% <FiArrowRight style={{ ...arrowStyle }} />  {e.soc_stop}%
              </SoftTypography>
            ) : (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.soc_start}% -{'>'} N/A
              </SoftTypography>
            ),
            odometer: (
              <SoftTypography style={{ textAlign: "center" }} variant="caption">
                {e.odometer} Km
              </SoftTypography>
            ),

            optefied: e.optefied_flag ? (
              <CheckIcon fontSize="medium" color="success" />
            ) : (
              <ClearIcon fontSize="medium" color="error" />
            ),
          };
        });
        setChargeRows(new_rows);
        setDisplayedRows(new_rows);
      }
    }
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <div style={{ display: "flex", alignItems: "flex-end", justifyItems: "center" }}>
          <SoftTypography variant="h2" style={{ marginBottom: "30px", marginRight: "10px" }}>
            History{" "}
          </SoftTypography>
        </div>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Card style={{ padding: "20px" }}>
              <div
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              >
                <div>
                  <SoftTypography variant="h6">Charging History and Logs</SoftTypography>
                  <SoftTypography variant="body2">
                    Charging history page logs the charging behavior all the vehicles. It displays
                    the history of selected vehicles on a page and can be useful to see the impact
                    on electricity bills and reduces emissions due to the optimized charging
                    management. The analytics can be downloaded for reporting and budgeting
                    purposes.
                  </SoftTypography>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mb={3} style={{ marginTop: "40px", minHeight: "200px" }}>
        <Card style={{ padding: "30px" }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 0.25, alignItems: "left", marginBottom: "20px" }}>
              <SoftTypography variant="h4" style={{ marginRight: "20px" }}>
                Summary
              </SoftTypography>
            </div>
            <div style={{ flex: 0.7 }}>
              <IconButton
                disabled={false}
                onClick={() => setCollapsed2(!isCollapsed2)}
                style={{ float: "right" }}
                size="medium"
                color="secondary"
                disableRipple={false}
              >
                {isCollapsed2 ? "Expand" : "Collapse"}
              </IconButton>
            </div>
          </div>
          <Collapse in={!isCollapsed2}>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={summaryColumns} rows={summaryRows}></Table>
            </SoftBox>
          </Collapse>
        </Card>
      </SoftBox>
      <SoftBox mb={3} style={{ minHeight: "200px" }}>
        <Card style={{ padding: "30px" }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 0.25, alignItems: "left", marginBottom: "20px" }}>
              <SoftTypography variant="h4" style={{ marginRight: "20px" }}>
                Charging Logs
              </SoftTypography>
              <SoftSelect
                placeholder="Select a vehicle"
                options={vehicleOptions}
                value={vehicleLog}
                onChange={(option) => {
                  setVehicleLog(option);
                  console.log(vehicleLog.value, monthLog.value);
                  if (option.value === "All" && monthLog.value === "All") {  // show all vehicles
                    setDisplayedRows(chargeRows);
                  } else if (option.value == "All") {  // if vehicle is all, filter by month
                    setDisplayedRows(chargeRows.filter((e) => e.month === monthLog.label));
                  } else if (monthLog.value === "All") {  // if month is all, filter by vehicle
                    setDisplayedRows(chargeRows.filter((e) => e.id === option.vin));
                  } else {
                    setDisplayedRows(chargeRows.filter((e) => e.id === option.vin && e.month === monthLog.label));
                  }
                }}
              />
            </div>
            <div
              style={{ flex: 0.3, alignItems: "right", marginBottom: "20px", marginLeft: "30px" }}
            >
              <SoftTypography variant="h4" style={{ marginRight: "20px" }}>
                Month
              </SoftTypography>
              <SoftSelect
                placeholder="Select a month"
                options={monthOptions}
                value={monthLog}
                onChange={(option) => {
                  setMonthLog(option);
                  console.log(vehicleLog.value, monthLog.value);
                  if (option.value === "All" && vehicleLog.value === "All") {  // show all vehicles
                    setDisplayedRows(chargeRows);
                  } else if (option.value === "All") {  // if month is all, filter by vehicle
                    setDisplayedRows(chargeRows.filter((e) => e.id === vehicleLog.vin));
                  } else if (vehicleLog.value === "All") {  // if vehicle is all, filter by month
                    setDisplayedRows(chargeRows.filter((e) => e.month === option.label));
                  } else {  // filter by both
                    setDisplayedRows(chargeRows.filter((e) => e.month === option.label && e.id === vehicleLog.vin));
                  }
                }}
              />
            </div>
            <div style={{ flex: 0.398 }}>
              <IconButton
                disabled={false}
                onClick={() => setCollapsed(!isCollapsed)}
                style={{ float: "right" }}
                size="medium"
                color="secondary"
                disableRipple={false}
              >
                {isCollapsed ? "Expand" : "Collapse"}
              </IconButton>
            </div>
          </div>
          <Collapse in={!isCollapsed}>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={chargingColumns} rows={displayedRows} />
            </SoftBox>
          </Collapse>
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default HistoryPage;
