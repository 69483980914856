import PropTypes from 'prop-types'

function ProgressBar2 ({value}) {
    /* Progress bar for % of cap column in Current Status Table */
    let bgColor;
    if (value <= 15){
        bgColor = "#fc9797";  // red
    } else if (value <= 33) {
        bgColor = "#ffff00";  // yellow
    } else {
    bgColor = "#cdf59b";  // green
    }
    
    const containerStyles = { // gray bar
      height: '10px',
      width: '100%',
      backgroundColor: "#e0e0de", // gray
      borderRadius: 100,
      marginLeft: 0,
      marginRight: 100,
      marginTop: -30,
    }
  
    const fillerStyles = { // green, yellow, or red bar
      height: '100%',
      width: value + '%',
      backgroundColor: bgColor,
      borderRadius: 'inherit',
      textAlign: 'center',
    }
  
    const labelStyles = { // value <span>
      padding: 10,
      top: 2,
      color: 'black',
      fontWeight: 'bold',
      position: 'relative',
    }
    return (
        <div style={containerStyles}>
          <div style={fillerStyles}>
          </div>
          <span style={labelStyles}>{value}%</span>
        </div> 
    );
  };
  
ProgressBar2.propTypes = {
    value: PropTypes.number.isRequired,
};

export default ProgressBar2;