import React, {useState} from 'react';
import PropTypes from 'prop-types'
import { abs } from 'stylis';

function ProgressBar ({batteryPercentage, vehicle}) {
    /* Progress bar for Battery column in Current Status Table */
    let bgColor;
    if (batteryPercentage < vehicle.battery_min) {
        bgColor = "#fc9797";  // red 
    } else if (vehicle.battery_min <= batteryPercentage && batteryPercentage < vehicle.battery_max) {
        bgColor = "#ffff00";  // yellow
    } else {
        bgColor = "#cdf59b";  // green
    }
    const containerStyles = { // gray bar
      height: 20,
      width: '100%',
      backgroundColor: "#e0e0de", // gray
      borderRadius: 100,
      marginLeft: 0,
      marginRight: 100,
      marginTop: -30,
    }
  
    const fillerStyles = { // green, yellow, or red bar
      height: '100%',
      width: batteryPercentage + '%',
      backgroundColor: bgColor,
      borderRadius: 'inherit',
      textAlign: 'center',
    }
  
    const labelStyles = { // battery percentage <span>
      padding: 10,
      top: 2,
      color: 'black',
      fontWeight: 'bold',
      position: 'relative',
    }
    const verticalLine = { // vertical line at battery_min
        marginLeft: vehicle.battery_min + '%',
        borderLeft: "1px dotted #000000",
        width: 2,
        height: 20,
        top: 10,
        position: 'relative',
        opacity: 0.6,
    }
    const verticalLine2 = { // vertical line at battery_max
        marginLeft: vehicle.battery_max + '%',
        borderLeft: "1px dotted #000000",
        width: 2,
        height: 20,
        top: -10,
        position: 'relative',
        opacity: 0.6,
    }
    const [showBatteryInfo, setShowBatteryInfo] = React.useState(false)  // show battery_min and battery_max on hover
    const onMouseEnter = () => setShowBatteryInfo(true)
    const onMouseLeave = () => setShowBatteryInfo(false)

    return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div style={verticalLine}></div><div style={verticalLine2}></div>
        <div style={containerStyles}>
          <div style={fillerStyles}>
            <span style={labelStyles}>{batteryPercentage}%</span>
          </div>
        </div> 
      {showBatteryInfo ? <span>min: {vehicle.battery_min}% max: {vehicle.battery_max}%</span> : null} {/* show battery_min and battery_max on hover */}
      </div>
    );
  };
  
ProgressBar.propTypes = {
    batteryPercentage: PropTypes.number.isRequired,
    vehicle: PropTypes.object.isRequired,
};

export default ProgressBar;